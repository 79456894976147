import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';
import { ApiKeyType } from '@parashift/shared/types';

export interface ApiKeyPlainModel {
  id: string;
  admin: boolean;
  api_key: string;
  created_at: string;
  employee: boolean;
  name: string;
  tenant_id: string;
  updated_at: string;
}

@Model({ type: ApiKeyType })
export class ApiKey extends BaseApiModel<ApiKeyPlainModel> {

  @Attribute()
  admin: boolean;

  @Attribute()
  api_key: string;

  @Attribute()
  created_at: string;

  @Attribute()
  employee: boolean;

  @Attribute()
  name: string;

  @Attribute()
  tenant_id: string;

  @Attribute()
  updated_at: string;
}
